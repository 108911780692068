<template>
  <!-- 用户基本信息 -->
  <el-dialog :close-on-click-modal="false" append-to-body title="基本信息" class="userInfo_dialog"
    :visible.sync="userInfoVisible" width="35%" @close="myClose">
    <el-form :model="userInfoForm" label-width="140px" class="userForm">
      <el-form-item label="集团：" style="margin-bottom: 0px" v-if="userInfoForm.group_name">
        <span>{{ userInfoForm.group_name }}</span>
      </el-form-item>
      <el-form-item label="账号：" style="margin-bottom: 0px">
        <span>{{ userInfoForm.user_name }}</span>
      </el-form-item>
      <el-form-item label="密码：" class="userInfoForm_password">
        <span style="-webkit-text-security: disc; text-security: disc">{{
          userInfoForm.password
        }}</span>
        <i class="iconfont lebo-xiugai" @click="userInfoPsdVisible = true"
          style="margin-left: 15px;cursor: pointer"></i>
      </el-form-item>
      <el-form-item label="有效期至：" style="margin-bottom: 0px"
        v-if="!isAdmin && !isLongTime(userInfoForm.valid_period_to)">
        <span>{{
          $moment(userInfoForm.valid_period_to).format("YYYY-MM-DD HH:mm:ss")
        }}</span>
      </el-form-item>
    </el-form>
    <el-dialog :close-on-click-modal="false" append-to-body title="修改密码" :visible.sync="userInfoPsdVisible" width="35%"
      @close="$refs.updatePsdFormFormRef.resetFields()">
      <el-form :model="updatePsdForm" status-icon :rules="updatePsdFormRules" style="padding: 0 30px"
        ref="updatePsdFormFormRef" label-width="80px">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input :type="isOldPsd ? 'text' : 'password'" v-model="updatePsdForm.oldPassword"
            @input="(e) => (updatePsdForm.oldPassword = validSpacing(e))">
            <i slot="suffix" style="cursor: pointer" @click="isOldPsd = !isOldPsd" :class="
              'iconfont ' + (isOldPsd ? 'iconzhengyanicon' : 'iconbiyan')
            "></i>
          </el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input :type="isNewPsd ? 'text' : 'password'" v-model="updatePsdForm.password"
            @input="(e) => (updatePsdForm.password = validSpacing(e))">
            <i slot="suffix" style="cursor: pointer" @click="isNewPsd = !isNewPsd" :class="
              'iconfont ' + (isNewPsd ? 'iconzhengyanicon' : 'iconbiyan')
            "></i>
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPassword">
          <el-input :type="isNewPsd1 ? 'text' : 'password'" v-model="updatePsdForm.checkPassword"
            @input="(e) => (updatePsdForm.checkPassword = validSpacing(e))">
            <i slot="suffix" style="cursor: pointer" @click="isNewPsd1 = !isNewPsd1" :class="
              'iconfont ' + (isNewPsd1 ? 'iconzhengyanicon' : 'iconbiyan')
            "></i>
          </el-input>
        </el-form-item>
        <el-form-item>注：8~14个字符，包含字母、数字，区分大小写</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="fnUpdatePsd">保 存</el-button>
      </span>
    </el-dialog>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="myClose">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import requst from '@/api/systemapi'
import { getDetailUser } from '@/api/userapi.js'
import { mapGetters } from 'vuex'
export default {
  name: 'userInfoDialog',
  data () {
    // 自定义验证规则  用户密码
    var psdRule = (rule, value, callback) => {
      const psdReg = /^[\w]{8,14}$/
      if (psdReg.test(value)) {
        return callback()
      }
      callback(new Error())
    }
    // 旧密码
    var oldPasswordRule = (rule, value, callback) => {
      if (value === this.userInfoForm.password) {
        return callback()
      } else {
        callback(new Error())
      }
    }
    // 确定密码
    var checkPsdRule1 = (rule, value, callback) => {
      if (value === this.updatePsdForm.password) {
        return callback()
      } else {
        callback(new Error())
      }
    }
    return {
      // 是否展示用户基本信息对话框
      userInfoVisible: true,
      userInfoForm: {
        group_name: '',
        user_name: '',
        password: '',
        valid_period_to: ''
      },
      // 是否展示修改密码对话框
      userInfoPsdVisible: false,
      // 用户修改密码
      updatePsdForm: {
        oldPassword: '',
        password: '',
        checkPassword: ''
      },
      // 设置密码验证规则
      updatePsdFormRules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          {
            validator: oldPasswordRule,
            message: '密码输入错误，请重新输入!',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            validator: psdRule,
            message: '8-14个字符，包含字母、数字，区分大小写',
            trigger: 'blur'
          }
        ],
        checkPassword: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          {
            validator: checkPsdRule1,
            message: '两次输入密码不一致，请重新输入!',
            trigger: 'blur'
          }
        ]
      },
      // 是否显示密码
      isOldPsd: false,
      isNewPsd: false,
      isNewPsd1: false
    }
  },
  created () {
    this.fngetDetailUser()
  },
  computed: {
    ...mapGetters(['getUserInfo', 'isAdmin'])
  },
  methods: {
    // 获取用户基本信息
    async fngetDetailUser () {
      const res = await getDetailUser({
        userid: this.getUserInfo.user_id
      })
      // console.log(res);
      this.userInfoForm = res.Data
    },
    // 修改密码
    fnUpdatePsd () {
      // 对整个表单进行校验
      this.$refs.updatePsdFormFormRef.validate(async (valid) => {
        // 成功
        if (valid) {
          const res = await requst.resetPwd({
            _id: this.getUserInfo.user_id,
            password: this.updatePsdForm.checkPassword
          })
          // console.log(res);
          if (res && res.Code === 200) {
            this.userInfoPsdVisible = false
            this.$msg.success(res.Message)
          }
        } else {
          return false
        }
      })
    },
    // 关闭用户信息对话框
    myClose () {
      this.userInfoVisible = false
      this.$emit('closeUserInfoDialog')
    }
  }
}
</script>

<style  scoped lang="less">
.userInfoForm_password{
  margin-bottom: 0px;
  .iconfont{
    font-size: 22px;
  }
}
</style>
